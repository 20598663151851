<template>
  <div>
    <div
      class="
        grid
        xl:grid-cols-6
        lg:grid-cols-5
        md:grid-cols-4
        sm:grid-cols-3
        grid-cols-2
      "
    >
      <productCard
        :product="product"
        v-for="product in fakeData"
        :key="product.title"
      />
    </div>
  </div>
</template>

<script>
import productCard from "./components/ProductCard.vue";

export default {
  data() {
    return {
      fakeData: [
        {
          id: 1,
          image: "https://placekitten.com/600/600",
          title: "Product 1",
          description: "Kedi 1",
          price: 100,
        },
        {
          id: 2,
          image: "https://placekitten.com/600/600",
          title: "Product 2",
          kg: [0.25, 0.5, 1],
          description: "Kedi 2 ",
          price: [100, 200, 300],
        },
        {
          id: 3,
          image: "https://placekitten.com/600/600",
          title: "Product 3",
          description: "Kedi  3",
          price: 300,
        },
        {
          id: 4,
          image: "https://placekitten.com/700/700",
          title: "Product 4",
          kg: [0.25, 0.5, 1],
          description: "Kedi 4 ",
          price: [100, 200, 300],
        },
      ],
    };
  },
  components: {
    productCard,
  },
};
</script>

<style></style>
