<template>
  <div>
    <vs-popup
      class="p-0"
      fullscreen
      :title="product.title"
      :active.sync="popupProduct"
    >
      <vs-row vs-type="flex" vs-justify="center" vs-align="flex-start">
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="flex-center"
          vs-w="6"
          vs-sm="12"
        >
          <img class="popupImage" :src="product.image" />
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="6"
          vs-sm="12"
        >
          <div class="w-full">
            <div class="popupProductTitle">
              {{ product.title }}
            </div>
            <div class="popupProductDesc">
              {{ product.description }}
            </div>
            <div v-if="product.kg" class="popupProductPrice">
              {{ "$" + GetPrice() }}
            </div>

            <div v-else class="popupProductPrice">
              {{ "$" + product.price }}
            </div>
            <div class="flex justify-between mt-20 pl-4">
              <input
                placeholder="Quantity"
                class="inputQuantityNumber p-0 m-0"
                type="number"
                v-model="quantity"
              />
              <vs-select
                v-if="product.kg"
                class="gramStyle"
                v-model="selectedKg"
              >
                <vs-select-item
                  :key="index"
                  :value="item"
                  :text="item"
                  v-for="(item, index) in product.kg"
                />
              </vs-select>
              <vs-button
                class="addToCart"
                color="dark"
                type="filled"
                icon="add"
                @click="AddProduct()"
              >
              </vs-button>
            </div>
          </div>
        </vs-col>
      </vs-row>
    </vs-popup>
    <div class="productCard m-2">
      <img
        :src="product.image"
        alt=""
        class="imageStyle"
        @click="popupProduct = true"
        style="cursor: pointer"
      />

      <div class="productIdPrice mt-2 ml-2 mr-2">
        {{ product.title }}
      </div>

      <div class="flex justify-between p-2">
        <input
          placeholder="Quantity"
          class="inputQuantityNumber p-0 m-0"
          type="number"
          v-model="quantity"
        />
        <vs-select v-if="product.kg" class="gramStyle" v-model="selectedKg">
          <vs-select-item
            :key="index"
            :value="item"
            :text="item"
            v-for="(item, index) in product.kg"
          />
        </vs-select>
        <vs-button
          class="addToCart"
          color="dark"
          type="filled"
          icon="add"
          @click="AddProduct()"
        ></vs-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["product"],
  data() {
    return {
      quantity: 1,
      popupProduct: false,
      selectedKg: 1,
    };
  },
  methods: {
    GetPrice() {
      return this.product.price[
        this.product.kg.findIndex((e) => e == this.selectedKg)
      ];
    },
    AddProduct() {
      var selectedPrice;

      if (this.product.kg) {
        let index = this.product.kg.findIndex((e) => e == this.selectedKg);
        selectedPrice = this.product.price[index];
      } else {
        selectedPrice = this.product.price;
      }
      this.$store.dispatch("addProductCart", {
        id: this.product.id,
        image: this.product.image,
        title: this.product.title,
        description: this.product.description,
        price: selectedPrice,
        quantity: this.quantity,
        kg: this.selectedKg,
      });
    },
  },
};
</script>

<style scoped>
.popupProductTitle {
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
}
.popupProductDesc {
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
}
.popupProductPrice {
  float: right;
  font-size: 1.5rem;
  font-weight: 600;
}
.productIdPrice {
  font-size: 14px;
  font-weight: 800;
  color: #000000;
}
.addButtonStyle {
  background-color: black;
  color: white;
  font-size: 2rem;
  font-weight: 600;
  border-radius: 10px;
}
.productCard {
  background-color: white;
  border-radius: 5px;
}

.addToCart {
  background-color: #000000;
  color: #ffffff;
  border-radius: 5px;
  border: none;
  max-height: 1.9em;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
}

.imageStyle {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.inputQuantityNumber {
  width: 4em;
  max-height: 2.2em;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  background-color: #ffffff;
  border: 1px solid #efefef;
  border-radius: 5px;
}
.gramStyle {
  max-width: 50px;
  max-height: 2.2em;
  font-size: 12px;
  font-weight: 600;
}

/* input number'da arttır azalt butonlarını saklama */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>

<style lang="scss">
.con-select {
  max-width: 80px;
}
.con-select .vs-select--input {
  padding: 5px;
}

@media (min-width: 736px) {
  .con-vs-popup.fullscreen .vs-popup {
    width: auto !important;
    height: auto !important;
    .popupImage {
      width: 100%;
      height: auto;
      border-radius: 5px;
    }
  }
}
@media (max-width: 736px) {
  .con-vs-popup.fullscreen .vs-popup {
    width: 100% !important;
    height: auto !important;
    .popupImage {
      width: 100%;
      height: auto;
      border-radius: 5px;
    }
  }
}
</style>
